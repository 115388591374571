<template>
  <Container>
    <v-card-text>
      <v-form @submit.prevent="login">
        <v-text-field
          v-model="email"
          :error-messages="emailError"
          :hide-details="emailError===''"
          outlined
          :label="$t('email')"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :error-messages="passwordError"
          :hide-details="passwordError===''"
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          :label="$t('password')"
          :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <div class="d-flex justify-end flex-wrap mt-3">
          <router-link :to="{name:'forget-password'}">
            Quên mật khẩu?
          </router-link>
        </div>

        <v-btn
          block
          :disabled="loading"
          color="primary"
          class="mt-6"
          type="submit"
        >
          <v-progress-circular
            v-if="loading"
            :size="14"
            :width="2"
            indeterminate
          />
          <span v-else>{{ $t('login') }}</span>
        </v-btn>
      </v-form>
    </v-card-text>

    <!-- create new account  -->
    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
      <span class="me-2">
        {{ $t('no-account') }}
      </span>
      <router-link :to="{name:'register'}">
        {{ $t('register') }}
      </router-link>
    </v-card-text>
  </Container>
</template>

<script>

import { ref } from '@vue/composition-api'
import Container from '../components/Container.vue'

export default {
  components: {
    Container,
  },

  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')

    return {
      isPasswordVisible,
      email,
      password,
    }
  },

  data() {
    return {
      errors: {},
      message: '',
      loading: false,
      email: '',
      password: '',
    }
  },

  computed: {
    emailError() {
      return this.errors.email || this.errors.auth || ''
    },
    passwordError() {
      return this.errors.password || this.errors.auth || ''
    },
  },

  methods: {
    login() {
      this.loading = true
      this.errors = {}
      const { email, password } = this
      this.$store.dispatch('auth/LOGIN', { email, password })
        .then(() => {
          this.$router.push({ path: '/' })
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          } else if (err.response.data.message) {
            this.message = err.response.data.message
          } else {
            this.message = 'Something is wrong!'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
